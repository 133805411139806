import { computed } from "mobx";

import { DateTime, DEFAULT_CALENDAR_FORMATS } from "@bps/utils";
import { ClinicalActivityDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";
import { ClinicalActivityStatusText } from "@shared-types/clinical/clinical-activity-status.enum.ts";
import { ClinicalActivityStatus } from "@shared-types/clinical/clinical-activity-status.type";

export class ClinicalActivity extends Model<ClinicalActivityDto> {
  @computed get id() {
    return this.dto.id;
  }

  @computed get patientId() {
    return this.dto.patientId;
  }

  @computed
  get changeLog() {
    return this.dto.changeLog;
  }
  @computed get activityType() {
    return this.dto.activityType;
  }

  @computed get descriptionCode() {
    return this.dto.descriptionCode;
  }

  @computed get freeText() {
    return this.dto.freeText;
  }

  @computed get activityStatus() {
    if (this.dto.activityStatus === ClinicalActivityStatus.Completed)
      return ClinicalActivityStatusText.Completed;

    if (!this.dto.dueDate && !this.dto.dueInVisits) return undefined;

    // Activity Status by DueDate
    if (this.dto.dueDate) {
      const dueDate = DateTime.fromISO(this.dto.dueDate);

      const currentDate = DateTime.today();

      if (dueDate.hasSame(DateTime.today(), "day"))
        return ClinicalActivityStatusText.Today;

      return dueDate > currentDate
        ? ClinicalActivityStatusText.Upcoming
        : ClinicalActivityStatusText.Overdue;
    }

    // Task status by DueInVisits
    const remainingVisits = this.dto.remainingVisits ?? 0;

    if (remainingVisits > 0) return ClinicalActivityStatusText.Upcoming;
    if (remainingVisits === 0) return ClinicalActivityStatusText.Today;
    if (remainingVisits < 0) return ClinicalActivityStatusText.Overdue;
    return undefined;
  }

  @computed get dueDate() {
    return this.dto.dueDate;
  }

  @computed get dueInVisits() {
    return this.dto.dueInVisits;
  }

  @computed get remainingVisits() {
    return this.dto.remainingVisits;
  }

  @computed get activityPriority() {
    return this.dto.activityPriority;
  }

  @computed get comment() {
    return this.dto.comment;
  }

  @computed get completionNotes() {
    return this.dto.completionNotes;
  }

  @computed get isDeleted() {
    return this.dto.isDeleted;
  }

  @computed get isCompleted() {
    return this.dto.activityStatus === ClinicalActivityStatus.Completed;
  }

  @computed get reasonForDelete() {
    return this.dto.reasonForDelete;
  }

  @computed get isSystemGenerated() {
    return this.dto.isSystemGenerated;
  }

  @computed get deletedComment() {
    return this.dto.deletedComment;
  }

  @computed get lockedBy() {
    return this.dto.lockedBy;
  }

  @computed get isLocked() {
    return !!this.dto.lockedBy;
  }

  @computed get businessRole() {
    return this.dto.businessRole;
  }

  @computed get secGroupId() {
    return this.dto.secGroupId;
  }

  @computed get completedBy() {
    return this.dto.completedBy;
  }

  @computed get completedDate() {
    const date =
      DateTime.fromISO(this.dto.completedDate) ??
      DateTime.fromISO(this.dto.changeLog?.updatedDate);
    return date?.toFormat(DEFAULT_CALENDAR_FORMATS.LAST_DAY);
  }

  @computed get metadata() {
    return this.dto.metadata;
  }
}
