import { action, computed } from "mobx";

import { TextBadgeColor } from "@bps/fluent-ui";
import { DateTime, getUniqueObjectsByKeys, to2dp } from "@bps/utils";
import { ClaimStatuses } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { isAllocationReferenceItemDto } from "@libs/gateways/billing/billing-gateway.utils.ts";
import {
  AllocationTransactionReferenceDto,
  BillingStatuses,
  InvoiceDto,
  InvoiceItemDto,
  ItemType,
  PaymentStatuses,
  TransactionPatientDto,
  TransactionReferenceDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { BillingRefKeyCodeAccessor } from "@stores/billing/BillingRef.ts";
import {
  getInvoiceAllocationStatusBadgeColor,
  getInvoiceAllocationStatuses
} from "@stores/billing/utils/invoice.utils.ts";
import { addressText } from "@stores/core/models/Address.ts";

import { AccSchedule } from "./AccSchedule.ts";
import { Transaction } from "./Transaction.ts";
import {
  billingStatusColours,
  paymentStatusColours
} from "./Transaction.types.ts";

export class Invoice extends Transaction<InvoiceDto, InvoiceItemDto> {
  _accSchedule: AccSchedule | undefined;

  constructor(dto: InvoiceDto) {
    super(dto);
    if (dto.accSchedule) {
      this._accSchedule = new AccSchedule(dto.accSchedule);
    }
  }

  get accSchedule() {
    return this._accSchedule;
  }

  get claimId() {
    if (this.items.length) {
      return this.items[0].accScheduleItem?.claimId;
    }
    return undefined;
  }

  get episodeOfCareId() {
    return this.items[0].episodeOfCareId;
  }

  get claimNumber() {
    if (this.items.length) {
      return this.items[0].accScheduleItem?.claimNumber;
    }
    return undefined;
  }

  get claimStatus() {
    if (this.items.length) {
      return this.items[0].accScheduleItem?.claimStatus;
    }
    return undefined;
  }

  private invoiceableStatuses: ClaimStatuses[] = [
    ClaimStatuses.Discharged,
    ClaimStatuses.Accepted,
    ClaimStatuses.Pending,
    ClaimStatuses.Held,
    ClaimStatuses.NotAvailable,
    ClaimStatuses.NotVerified
  ];

  get hasACCComment() {
    return this.items.some(x => x.accScheduleItem?.statusReason);
  }

  get claimReadyToInvoice() {
    return (
      this.claimStatus && this.invoiceableStatuses.includes(this.claimStatus)
    );
  }

  get claimSubmittable() {
    return !this.accSchedule?.claimSubmittable;
  }

  get calendarEventId() {
    return this.dto.items[0]?.calendarEventId;
  }

  get patientId() {
    return this.dto.items[0]?.patientId;
  }

  get patientContact(): TransactionPatientDto {
    if (!!this.dto.items.length) {
      return this.dto.items[0].patient;
    } else {
      throw new Error("invoice patient is not defined");
    }
  }

  get patientName() {
    return `${this.patientFirstName} ${this.patientLastName}`;
  }

  get patientFirstName() {
    return this.patientContact.firstName;
  }

  get patientLastName() {
    return this.patientContact.lastName;
  }

  get patientAddress() {
    return this.patientContact?.address;
  }

  get patientAddressString(): string {
    return this.patientAddress ? addressText(this.patientAddress) : "";
  }

  get patientPhone() {
    return this.patientContact?.phone;
  }

  get userId() {
    return this.dto.items[0]?.userId;
  }

  get user() {
    return this.dto.items[0]?.user;
  }

  get userFirstName() {
    return this.user.firstName;
  }

  get userLastName() {
    return this.user.lastName;
  }

  get userTitle() {
    return this.user.title;
  }

  get userNameWithTitle() {
    return `${this.userTitle || ""} ${this.userFirstName} ${this.userLastName}`;
  }

  get paymentStatus() {
    return this.dto.paymentStatus;
  }

  get paid() {
    return this.dto.paid;
  }

  get writtenOff() {
    return this.dto.writtenOff;
  }

  get isFullyPaid() {
    return this.paymentStatus === PaymentStatuses.paid;
  }

  get credited() {
    return this.dto.credited;
  }

  get isFullyCredited() {
    return this.dto.credited === this.dto.total;
  }

  get owing() {
    if (this.status === BillingStatuses.current) {
      return to2dp(
        this.dto.total - (this.paid || 0) - (this.dto.writtenOff || 0)
      );
    }
    return undefined;
  }

  @computed
  get isOwingReduced() {
    return this.total - (this.owing ?? 0) > 0;
  }

  @computed
  get isNotOwing() {
    return this.owing === 0;
  }

  private getTransactionReference(itemType?: ItemType) {
    const refs = this.items.flatMap(item => {
      if (item.references) {
        return itemType
          ? item.references.filter(x => x.itemType === itemType)
          : item.references;
      } else {
        return [];
      }
    });
    return getUniqueObjectsByKeys({
      array: refs,
      keys: [nameof<TransactionReferenceDto>("transactionId")]
    });
  }

  get allocationReferences(): AllocationTransactionReferenceDto[] {
    return this.getTransactionReference(ItemType.Allocation).filter(
      isAllocationReferenceItemDto
    );
  }

  get transactionReferences() {
    return this.getTransactionReference().map(x => x.transaction);
  }

  get statusColour(): TextBadgeColor {
    if (this.allocationStatuses.length > 0) {
      const primaryStatus = this.allocationStatuses[0];
      return getInvoiceAllocationStatusBadgeColor(primaryStatus);
    } else if (this.dto.paymentStatus) {
      return paymentStatusColours[this.dto.paymentStatus];
    } else {
      return billingStatusColours[this.status];
    }
  }

  get viewLink() {
    return routes.accounts.invoices.invoice;
  }

  get viewPermission() {
    return Permission.AccountHistoryAllowed;
  }

  get allocationStatuses() {
    const owing = this.owing || 0;
    const paid = this.paid || 0;
    const writtenOff = this.writtenOff || 0;
    const credited = this.credited || 0;
    const status = this.status;
    return getInvoiceAllocationStatuses({
      owing,
      paid,
      writtenOff,
      status,
      credited
    });
  }

  get statusText(): BillingRefKeyCodeAccessor {
    if (this.dto.paymentStatus) {
      return { key: "paymentStatuses", code: this.dto.paymentStatus };
    } else {
      return { key: "billingStatuses", code: this.dto.status };
    }
  }

  getItemStatusText = (id: string) => {
    const item = this.items.find(x => x.id === id);
    if (item?.paymentStatus) {
      return { key: "paymentStatuses", code: item?.paymentStatus };
    } else {
      return { key: "billingStatuses", code: item?.status };
    }
  };

  getItemStatusColour(id: string): TextBadgeColor {
    const item = this.items.find(x => x.id === id);
    if (item?.paymentStatus) {
      return paymentStatusColours[item?.paymentStatus];
    } else if (item?.status) {
      return billingStatusColours[item?.status];
    } else {
      return billingStatusColours[BillingStatuses.cancelled];
    }
  }

  getStatusIcon(): string {
    if (this?.status === BillingStatuses.cancelled) {
      return "Remove";
    } else if (this?.status === BillingStatuses.adjusted) {
      return "PageEdit";
    } else if (!this.isNotOwing) {
      return "BpOwing";
    } else {
      return super.getStatusIcon();
    }
  }

  getStatusTooltip(): string {
    if (this.isAdjusted) return "Adjusted";
    if (this.isFullyCredited) return "Credited";
    if (this.allocationStatuses.length > 0) return this.allocationStatuses[0];
    return super.getStatusTooltip();
  }

  getInvoiceItem = (id: string) => {
    return this.items.find(x => x.id === id);
  };

  @computed
  get sentDateTime(): Date | undefined {
    return DateTime.jsDateFromISO(this.dto.sentDateTime);
  }

  @action
  updateFromDto(dto: InvoiceDto) {
    super.updateFromDto(dto);
    if (dto.accSchedule) {
      this.accSchedule
        ? this.accSchedule.updateFromDto(dto.accSchedule)
        : (this._accSchedule = new AccSchedule(dto.accSchedule));
    }
  }
}
