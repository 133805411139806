import { action, observable, runInAction } from "mobx";

import { DateTime } from "@bps/utils";
import { AppointmentStartingPoints } from "@libs/analytics/app-insights/app-insights.enums.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";

interface PatientMatchInfo {
  calendarEventId: string;
  onCreateContact?: (contactId: string) => void;
}

export interface AppointmentDialogOptions {
  type?: CalendarEventType;
  id?: string;
  initialValues?: Partial<AppointmentFormValues>;
  secondColumnContent?: SecondColumnContent | undefined;
  showAddToGroupApptDialog?: boolean;
  onCancel?: () => void;
  onSubmitted?: (values: AppointmentFormValues) => void;
}

export class BookingUi {
  public newAppointmentStartingPoint: string | undefined;

  // added for global appointment dialog, to avoid problems with url base approach of opening modals
  @observable
  currentAppointment: AppointmentDialogOptions | undefined = undefined;
  @observable
  formQRCodeUrl: string | undefined = undefined;

  hideFormQRCodeUrl = () => {
    runInAction(() => {
      this.formQRCodeUrl = undefined;
    });
  };
  public showFormQRCodeUrl = (val: string) => {
    runInAction(() => {
      this.formQRCodeUrl = val;
    });
  };

  @observable
  cancelCalendarEventRecurrenceId: string | undefined;

  @observable
  cancelledAppointmentDetails:
    | {
        timeOptions:
          | { startTime: DateTime | undefined; endTime: DateTime | undefined }
          | undefined;
        providerId: string | undefined;
        orgUnitId: string | undefined;
        selectedCount: number;
      }
    | undefined;

  @observable
  cancelCalendarEventId: string | undefined;

  @observable
  showCancelCalendarEventDialog: boolean;

  @observable
  isEditSingleEvent: boolean = false;

  @observable
  hidePreviousExceptions: boolean = true;

  @observable
  hidePreviousReserves: boolean = true;

  @observable
  showAttendeeCancellationDialog: boolean = false;

  @observable
  cancelCalendarEventAttendeeId: string | undefined;

  @observable
  recentlyCreatedReminderReplyId?: string;

  @observable
  showPatientMatchingDialog: boolean = false;

  patientMatchInfo: PatientMatchInfo | undefined;

  @action
  deleteCancelledAppointmentDetails = () => {
    this.cancelledAppointmentDetails = undefined;
  };

  @action
  setSecondColumnContent = (content?: SecondColumnContent) => {
    if (this.currentAppointment) {
      this.currentAppointment.secondColumnContent = content;
    }
  };

  @action
  updateSelectedCount = (count: number) => {
    if (this.cancelledAppointmentDetails) {
      this.cancelledAppointmentDetails.selectedCount = count;
    }
  };

  @action
  setCancelledAppointmentDetails = (
    timeOptions: { startTime: DateTime; endTime: DateTime },
    providerId: string,
    orgUnitId: string
  ) => {
    this.cancelledAppointmentDetails = {
      timeOptions,
      providerId,
      orgUnitId,
      selectedCount: 0
    };
  };

  @action
  setCancelCalendarEventRecurrenceId = (value?: string) => {
    this.cancelCalendarEventRecurrenceId = value;
  };

  @action
  setShowCancelCalendarEventDialog = (
    value: boolean,
    calendarEventId?: string
  ) => {
    this.showCancelCalendarEventDialog = value;
    if (value) {
      this.cancelCalendarEventId = calendarEventId;
    }
  };

  @action
  setCancelCalendarEventId = (value?: string) => {
    this.cancelCalendarEventId = value;
    if (value === undefined) {
      this.showCancelCalendarEventDialog = false;
    }
  };

  @action
  showCalendarEventDialog = (
    options: AppointmentDialogOptions,
    startingPoint?: AppointmentStartingPoints
  ) => {
    this.currentAppointment = options;
    this.newAppointmentStartingPoint = startingPoint;
  };

  @action
  hideCalendarEventDialog = () => {
    this.currentAppointment = undefined;
    this.newAppointmentStartingPoint = undefined;
  };

  @action
  onTogglePreviousExceptions(hidePreviousExceptions: boolean) {
    runInAction(() => {
      this.hidePreviousExceptions = hidePreviousExceptions;
    });
  }

  @action
  onTogglePreviousReserves(hidePreviousReserves: boolean) {
    runInAction(() => {
      this.hidePreviousReserves = hidePreviousReserves;
    });
  }

  @action
  setCancelCalendarEventAttendeeId = (value?: string) => {
    this.cancelCalendarEventAttendeeId = value;
  };

  @action
  setShowAttendeeCancellationDialog = (
    value: boolean,
    calendarEventId?: string,
    patientId?: string
  ) => {
    runInAction(() => {
      this.showAttendeeCancellationDialog = value;
      if (calendarEventId) {
        this.cancelCalendarEventId = calendarEventId;
      }
      if (patientId) {
        this.setCancelCalendarEventAttendeeId(patientId);
      }
    });
  };

  @action
  setShowPatientMatchingDialog = (value: boolean) => {
    this.showPatientMatchingDialog = value;
  };

  setPatientMatchInfo = (value?: PatientMatchInfo) => {
    this.patientMatchInfo = value;
  };

  startPatientMatchWorkflow = (calendarEventId: string) => {
    this.setPatientMatchInfo({
      ...this.patientMatchInfo,
      calendarEventId
    });
    this.setShowPatientMatchingDialog(true);
  };

  @observable
  lastUpdatedAppointmentTypeETag: string | undefined;

  @observable public lastUpdatedCalendarEventData:
    | Pick<EntityEventData, "etag" | "action" | "id">
    | undefined;
}
