import { computed } from "mobx";

import { ClinicalReminderPreferenceDto } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { Model } from "@libs/models/Model.ts";

export class ClinicalReminderPreference extends Model<ClinicalReminderPreferenceDto> {
  @computed
  get clinicalReminderReasons() {
    return this.dto.clinicalReminderReasons;
  }

  get allowReminderFreeText() {
    return this.dto.allowReminderFreeText;
  }
}
