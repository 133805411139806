import {
  ClinicalActivityClinicalDataItemDto,
  ClinicalActivityDto,
  ClinicalTaskClinicalDataItemDto,
  ClinicalTaskDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

export const getClinicalTaskDtos = (
  clinicalTasks: ClinicalTaskClinicalDataItemDto[] | undefined,
  isDeleted: boolean
) => {
  return (
    clinicalTasks?.map(
      x =>
        ({
          id: x.id,
          patientId: x.patientId,
          taskType: x.taskType,
          dueDate: x.dueDate,
          dueInVisits: x.dueInVisits,
          remainingVisits: x.remainingVisits,
          priority: x.priority,
          comment: x.comment,
          isCompleted: x.isCompleted,
          lockedBy: x.lockedBy,
          isLocked: !!x.lockedBy,
          completionNotes: x.completionNotes,
          isDeleted,
          isSystemGenerated: x.isSystemGenerated,
          claimNumber: x.claimNumber,
          secGroupId: x.secGroupId,
          completedBy: x.completedBy,
          completedDate: x.completedDate,
          eTag: "",
          changeLog: mapToChangeLog(x)
        }) as ClinicalTaskDto
    ) || []
  );
};

export const getClinicalActivityDtos = (
  clinicalActivities: ClinicalActivityClinicalDataItemDto[] | undefined,
  isDeleted: boolean
): ClinicalActivityDto[] => {
  return (
    clinicalActivities?.map(x => ({
      id: x.id,
      patientId: x.patientId,
      activityType: x.activityType,
      descriptionCode: x.descriptionCode,
      freeText: x.freeText,
      dueDate: x.dueDate,
      dueInVisits: x.dueInVisits,
      remainingVisits: x.remainingVisits,
      activityPriority: x.activityPriority,
      comment: x.comment,
      activityStatus: x.activityStatus,
      lockedBy: x.lockedBy,
      isLocked: !!x.lockedBy,
      completionNotes: x.completionNotes,
      isDeleted,
      isSystemGenerated: x.isSystemGenerated,
      secGroupId: x.secGroupId,
      completedBy: x.completedBy,
      completedDate: x.completedDate,
      eTag: "",
      changeLog: mapToActivityChangeLog(x),
      metadata: x.metadata
    })) || []
  );
};

const mapToChangeLog = (
  clinicalTaskClinicalDataItemDto: ClinicalTaskClinicalDataItemDto
) => {
  if (clinicalTaskClinicalDataItemDto.createLog) {
    const createLog = clinicalTaskClinicalDataItemDto.createLog;
    const updateLog = clinicalTaskClinicalDataItemDto.updateLog;
    return {
      createdBy: createLog.createdById,
      createdDate: createLog.createdDateTime,
      updatedBy: updateLog?.updatedById,
      updatedDate: updateLog?.updatedDateTime
    };
  }
  return undefined;
};

const mapToActivityChangeLog = (
  clinicalActivityClinicalDataItemDto: ClinicalActivityClinicalDataItemDto
) => {
  if (clinicalActivityClinicalDataItemDto.createLog) {
    const createLog = clinicalActivityClinicalDataItemDto.createLog;
    const updateLog = clinicalActivityClinicalDataItemDto.updateLog;
    return {
      createdBy: createLog.createdById,
      createdDate: createLog.createdDateTime,
      updatedBy: updateLog?.updatedById,
      updatedDate: updateLog?.updatedDateTime
    };
  }
  return undefined;
};
