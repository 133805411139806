import { computed } from "mobx";
import { parse, stringify } from "query-string";
import { createPath, To } from "react-router-dom";

import { RouteInfo } from "@libs/routing/routes.ts";
import { Router, RouterState } from "@remix-run/router";

import { ReactRouterStore } from "./ReactRouterStore.ts";

export type MethodType = "push" | "replace";

export type Location = RouterState["location"];

const queryStringParams = (
  query: string,
  ...keys: string[]
): { [key: string]: string | null | undefined } => {
  const qs = parse(query);
  const result: { [key: string]: string | null | undefined } = {};
  for (const key of keys) {
    const value = qs[key];
    result[key] = Array.isArray(value) ? value[0] : value;
  }
  return result;
};

const hasQueryStringParam = (query: string, key: string) => {
  return key in parse(query);
};

export class RouterStore {
  // A temporary store based on mobx router but using v6 router instead of history.
  innerStore: ReactRouterStore = new ReactRouterStore();

  //Temporary innerstore must be set after createBrowserRouter is created
  public init(appRouter: Router) {
    this.innerStore.init(appRouter);
  }

  //Mocks history implementation
  public createHref(to: To) {
    return typeof to === "string" ? to : createPath(to);
  }

  @computed
  get location() {
    if (!this.innerStore.location) {
      throw Error("Trying to use location before routing is created.");
    }
    return this.innerStore.location;
  }

  push = (path: To | Partial<Location>, state?: any) => {
    if (typeof path === "string") {
      const action = isLocationSame(this.location, path, state)
        ? this.innerStore.replace
        : this.innerStore.push;

      action(path, { state });
    } else {
      const action = isLocationSame(this.location, path)
        ? this.innerStore.replace
        : this.innerStore.push;
      let actionState = {};
      if ("state" in path) {
        actionState = path.state ?? actionState;
      }
      action(path, { state: { ...actionState, ...state } });
    }
  };

  replace = (location: To | Location, state?: any) => {
    if (typeof location === "string") {
      this.innerStore.replace(location, { state });
    } else {
      let locationState = {};
      if ("state" in location) {
        locationState = location.state ?? locationState;
      }
      this.innerStore.replace(location, {
        state: { ...locationState, ...state }
      });
    }
  };

  back = () => this.innerStore.back();

  /**
   * Utility method to push a new url that replaces given
   * query string parameters
   * @param parameters a key value object of parameters to push
   * **/
  pushQueryStringParams(parameters: { [key: string]: any }) {
    const qs = parse(this.location && this.location.search);
    for (const key in parameters) {
      delete qs[key];
    }

    this.push(
      `?${stringify({
        ...qs,
        ...parameters
      })}`
    );
  }

  /**
   * Utility method to replace a new url that replaces given
   * query string parameters
   * @param parameters a key value object of parameters to push
   * **/
  private replaceQueryStringParams(parameters: { [key: string]: any }) {
    const qs = parse(this.location && this.location.search);
    for (const key in parameters) {
      delete qs[key];
    }

    this.replace(
      `?${stringify({
        ...qs,
        ...parameters
      })}`
    );
  }

  /**
   * Utility method to push a new url that replaces a given
   * query string parameter
   * @param key the parameter key
   *
   * @param value when undefined, it is equivalent to removing the query string parameter.
   * null is equivalent to setting a query string parameter without value.
   * Otherwise, the given value is passed in
   */
  pushQueryStringParam(key: string, value?: string | undefined | null) {
    this.pushQueryStringParams({ [key]: value });
  }

  replaceQueryStringParam(
    key: string,
    value?: string | undefined | null | string[]
  ) {
    this.replaceQueryStringParams({ [key]: value });
  }

  hasQueryStringParam(key: string) {
    return hasQueryStringParam(this.location && this.location.search, key);
  }

  queryStringParams(...keys: string[]): {
    [key: string]: string | null | undefined;
  } {
    return queryStringParams(this.location && this.location.search, ...keys);
  }

  /**
   * Returns the value of a given query string parameter
   * or undefined if absent.
   * @param key
   */
  queryStringParam(key: string) {
    return this.queryStringParams(key)[key];
  }

  /**
   * Utility method to push a new url that removes
   * given query string parameter(s)
   * @param key the parameter key
   */
  removeQueryStringParams(...keys: string[]) {
    const qs = { ...parse(this.location.search) };

    for (const key of keys) {
      delete qs[key];
    }

    this.push({
      search: stringify(qs)
    });
  }

  /**
   * Utility method to check if a given route is matched
   * @param routeInfo
   */
  match<Params extends { [K in keyof Params]?: string | undefined }>(
    routeInfo: RouteInfo<Params>,
    partialMatch?: boolean
  ) {
    return routeInfo.match(this.location.pathname, partialMatch);
  }

  // to navigate to an accounts page from a non-accounts, a link needs to use
  //  getStateWithFromQuery or the route needs to be changed using pushWithFromQuery

  getStateWithFromQuery = (
    from: string | Partial<Location> = this.location
  ) => {
    let fromString = "";
    if (typeof from === "string") {
      fromString = from;
    } else {
      fromString = (from.pathname ?? "") + (from.search ?? "");
    }
    return fromString
      ? {
          from: fromString
        }
      : undefined;
  };

  pushWithFromQuery = (path: To, state?: any) => {
    return this.push(path, { ...state, ...this.getStateWithFromQuery() });
  };

  // to navigate among accounts pages, a link needs to use getRouteState or mergeRouteStates
  // or the route needs to be changed using pushRetainingState or replaceRetainingState

  getRouteState = () => {
    return { ...this.location.state };
  };

  mergeRouteStates = (state: any = {}) => {
    const oldState = this.getRouteState();
    return { ...oldState, ...state };
  };

  pushRetainingState = (path: To, state?: any) => {
    return this.push(path, this.mergeRouteStates(state));
  };

  pushQueryStringParamsRetainingState = (parameters: {
    [key: string]: any;
  }) => {
    const qs = parse(this.location.search);

    const searchString = stringify({
      ...qs,
      ...parameters
    });

    this.push(
      {
        pathname: this.location.pathname,
        search: `${searchString ? "?" : ""}${searchString}`
      },
      this.getRouteState()
    );
  };

  public replaceRetainingState = (path: To, state?: any) => {
    return this.replace(path, this.mergeRouteStates(state));
  };

  goToFromState = (
    defaultPath: string,
    options?: { method?: MethodType; retainState?: boolean }
  ): void => {
    const { method = "replace", retainState = false } = options || {};
    const path = this.location.state?.from || defaultPath;
    if (method === "push") {
      if (retainState) {
        this.pushRetainingState(path);
      } else {
        this.push(path);
      }
    } else {
      if (retainState) {
        this.replaceRetainingState(path);
      } else {
        this.replace(path);
      }
    }
  };
}

function isLocationSame(location: Location, path: To, state?: any): boolean;
function isLocationSame(
  location: Location,
  locationDescriptor: Partial<Location>
): boolean;
function isLocationSame(
  location: Location,
  path: Partial<Location> | To,
  state?: any
): boolean {
  if (typeof path === "string") {
    return (
      path === location.pathname + location.search + location.hash &&
      location.state === state
    );
  }
  if ("state" in location && "state" in path) {
    return (
      path.pathname === location.pathname &&
      path.search === location.search &&
      path.hash === location.hash &&
      path.state === location.state
    );
  }

  return (
    path.pathname === location.pathname &&
    path.search === location.search &&
    path.hash === location.hash
  );
}
