import { action, computed, observable, runInAction, values, when } from "mobx";

import { flatten } from "@bps/fluent-ui";
import { NotFoundError } from "@bps/http-client";
import {
  DATE_FORMATS,
  DateTime,
  isDefined,
  TIME_FORMATS,
  unique
} from "@bps/utils";
import { AppointmentSearch } from "@libs/analytics/app-insights/app-insights.enums.ts";
import {
  Baggage,
  NewAppointmentBaggageObject,
  UIOptionBaggage
} from "@libs/analytics/app-insights/app-insights.types.ts";
import { Entity } from "@libs/api/hub/Entity.ts";
import { EntityEventData } from "@libs/api/hub/EntityEventData.ts";
import { EventAction } from "@libs/api/hub/EventAction.ts";
import { IHubGateway } from "@libs/api/hub/HubGateway.ts";
import {
  deepEqualResolver,
  sharePendingPromise
} from "@libs/decorators/sharePendingPromise.ts";
import { Country } from "@libs/enums/country.enum.ts";
import {
  AddAppointmentCancellationReasonDto,
  AddAppointmentConfirmationCampaignDto,
  AddAppointmentEncountersDto,
  AddAppointmentReminderJobDto,
  AddAppointmentTypeDto,
  AddBookingTenantSettingsDto,
  AddCalendarEventDto,
  AddCalendarEventReminderDto,
  AddRecurrenceDto,
  AddWaitingListItemDto,
  AppointmentCancellationReasonDto,
  AppointmentConfirmationCampaignDto,
  AppointmentEncountersDto,
  AppointmentReminderJobDto,
  AppointmentReminderJobPreviewArgs,
  AppointmentReminderJobPreviewDto,
  AppointmentReminderJobRunArgsDto,
  AppointmentReminderJobRunSummaryDto,
  AppointmentStatusCode,
  AppointmentTypeDto,
  AttendeeTypeEnum,
  CalendarEventAppointmentStatusChangeDto,
  CalendarEventDto,
  CalendarEventFormInstanceDTO,
  CalendarEventReminderDto,
  CalendarEventReminderMessageDto,
  CalendarEventReminderReplyDto,
  CalendarEventReminderSearchArgs,
  CalendarEventStatus,
  CalendarEventType,
  CancelCalendarSeriesDto,
  GetAppointmentCancellationReasonDto,
  GetAppointmentEncountersRequest,
  GetCalendarEventReminderArgs,
  GetCalendarEventsDto,
  GetRecurringAppointmentConflictDto,
  OrgUnitAvailabilityDto,
  PatchAppointmentConfirmationCampaignDto,
  PatchAppointmentReminderJobDto,
  PatchAppointmentTypeDto,
  PatchBookingTenantSettingsDto,
  PatchCalendarEventDto,
  PatchOrgUnitAvailabilityDto,
  PatchRecurrenceDto,
  PatchUserAvailabilityDto,
  PatchUserUnavailabilityDto,
  RecurrenceDto,
  SingleCalendarEventReminder,
  UpdateAppointmentCancellationReasonDto,
  UpdateCalendarEventReminderDto,
  UpdateWaitingListItemDto,
  UserAvailabilityDto,
  UserUnavailabilityDto,
  ValidateRecurrenceSeriesConflictDto,
  WaitingListItemDto
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { IBookingGateway } from "@libs/gateways/booking/BookingGateway.interface.ts";
import { patchModel } from "@libs/models/model.utils.ts";
import { maybePromiseObservable } from "@libs/utils/promise-observable/promise-observable.utils.ts";
import {
  catchNotFoundError,
  getOrThrow,
  wildCardCheck
} from "@libs/utils/utils.ts";
import { AppointmentTypesFilter } from "@shared-types/booking/appointment-type-filter.type.ts";
import { AvailabilitySlotQuery } from "@shared-types/booking/availability-slot-query.interface.ts";
import { CalendarEventsFilterInterface } from "@shared-types/booking/calendar-events-filter.interface.ts";
import { OrgUnitWorkingHoursQueryInterface } from "@shared-types/booking/org-unit-working-hours-query.interface.ts";
import { UserWorkingHoursQueryInterface } from "@shared-types/booking/user-working-hours-query.interface.ts";
import { UsersOrgUnitTimeRanges } from "@shared-types/booking/users-org-unit-time-ranges.type.ts";
import { UsersTimeRanges } from "@shared-types/booking/users-time-ranges.type.ts";
import type { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { AppointmentConfirmationCampaign } from "@stores/booking/models/AppointmentConfirmationCampaign.ts";
import { AppointmentEncounters } from "@stores/booking/models/AppointmentEncounters.ts";
import { TimeRanges } from "@stores/booking/models/TimeRanges.ts";
import { User } from "@stores/core/models/User.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import type { Store } from "@stores/types/store.type.ts";
import { mergeModel } from "@stores/utils/store.utils.ts";

import { BookingUi } from "./BookingUi.ts";
import { AppointmentCancellationReason } from "./models/AppointmentCancellationReason.ts";
import { AppointmentReminderJob } from "./models/AppointmentReminderJob.ts";
import { AppointmentType } from "./models/AppointmentType.ts";
import { AvailabilitySlots } from "./models/AvailabilitySlots.ts";
import { BookingTenantSettings } from "./models/BookingTenantSettings.ts";
import { CalendarEvent, isKnownAttendeeType } from "./models/CalendarEvent.ts";
import { CalendarEventReminder } from "./models/CalendarEventReminder.ts";
import { CalendarEventReminderMessage } from "./models/CalendarEventReminderMessage.ts";
import { CalendarEventReminderSearchModel } from "./models/CalendarEventReminderSearchModel.ts";
import { OrgUnitAvailability } from "./models/OrgUnitAvailability.ts";
import { RecurrenceModel } from "./models/RecurrenceModel.ts";
import { TimeRangesOrgUnit } from "./models/TimeRangesOrgUnit.ts";
import { UserAvailabilityModel } from "./models/UserAvailabilityModel.ts";
import { UserUnavailabilityModel } from "./models/UserUnavailabilityModel.ts";
import { WaitingListItemModel } from "./models/WaitingListModel.ts";
import { BookingRef } from "./ref/BookingRef.ts";

export interface WaitingListFilter {
  search?: string;
  orgUnitIds?: string[];
  attendees?: string[];
  anyProvider?: number;
  priority?: string[];
  duration?: string[];
  calenderEventId?: string;
  appointmentTypeId?: string[];
  expiryDate?: string;
  patientName?: string;
}

export const defaultWaitingListFilter: WaitingListFilter = {
  patientName: "",
  priority: [],
  appointmentTypeId: [],
  duration: []
};

export class BookingStore implements Store<BookingStore, BookingRef> {
  constructor(
    private gateway: IBookingGateway,
    public hub: IHubGateway
  ) {
    this.ref = new BookingRef(this.gateway);
  }

  calendarEventsMap = observable.map<string, CalendarEvent>();
  userAvailabilityMap = observable.map<string, UserAvailabilityModel>();
  userUnavailabilityMap = observable.map<string, UserUnavailabilityModel>();
  orgUnitAvailabilityMap = observable.map<string, OrgUnitAvailability>();
  recurrenceMap = observable.map<string, RecurrenceModel>();
  waitingListsMap = observable.map<string, WaitingListItemModel>();
  appointmentCancellationReasonsMap = observable.map<
    string,
    AppointmentCancellationReason
  >();
  calendarEventRemindersMap = observable.map<string, CalendarEventReminder>();

  appointmentReminderJobsMap = observable.map<string, AppointmentReminderJob>();

  appointmentConfirmationCampaignsMap = observable.map<
    string,
    AppointmentConfirmationCampaign
  >();
  calendarEventRemindersMessagesMap = observable.map<
    string,
    CalendarEventReminderMessage
  >();
  appointmentTypesMap = observable.map<string, AppointmentType>();
  appointmentEncountersMap = observable.map<string, AppointmentEncounters>();
  @observable
  tenantSettings: BookingTenantSettings | undefined;

  root: IRootStore;
  ref: BookingRef;
  ui = new BookingUi();

  afterAttachRoot() {
    this.hub.onEntityEvent(Entity.WaitingList, this.onWaitingListItemChanged);
    this.hub.onEntityEvent(
      Entity.AppointmentReminderJob,
      this.onAppointmentReminderJobChanged
    );
    this.hub.onEntityEvent(Entity.CalendarEvent, this.onCalendarEventChange);
    this.hub.onEntityEvent(
      Entity.CalendarEventExtension,
      this.onExtensionUpdateEvent
    );

    this.hub.onEntityEvent(
      Entity.CalendarEventReminderReply,
      this.onCalendarEventReminderReplyChanged
    );
    this.hub.onEntityEvent(Entity.AppointmentType, this.onAppointmentTypeEvent);
    this.hub.onEntityEvent(
      Entity.BookingTenantSettings,
      this.onTenantSettingsUpdateEvent
    );
  }

  private onCalendarEventReminderReplyChanged = async (
    event: EntityEventData<{ id: string }>
  ) => {
    if (event.action === EventAction.Create) {
      runInAction(() => {
        this.ui.recentlyCreatedReminderReplyId = event.id;
      });
    }
  };

  private onAppointmentReminderJobChanged = async (
    event: EntityEventData<{ id: string }>
  ) => {
    if (event.action === EventAction.Delete) {
      runInAction(() => {
        this.appointmentReminderJobsMap.delete(event.id);
        this.recentlyDeletedScheduleListItemId = event.id; // Used id since etag is undefined in Delete event
      });
    } else {
      // Create and Update
      const cachedReminderJob = this.appointmentReminderJobsMap.get(event.id);
      // Does not exist or stale in the cache
      if (!cachedReminderJob || cachedReminderJob.eTag !== event.etag) {
        await this.getAppointmentReminderJob(event.id);
        runInAction(() => {
          this.recentlyDeletedScheduleListItemId = event.etag;
        });
      }
    }
  };

  private onCalendarEventChange = async (message: EntityEventData) => {
    try {
      const calendarEventId = message.id;
      const existingCalendarEvent = this.calendarEventsMap.get(calendarEventId);

      if (message.action === EventAction.Delete && existingCalendarEvent) {
        this.calendarEventsMap.delete(existingCalendarEvent.id);
        runInAction(() => {
          this.ui.lastUpdatedCalendarEventData = message;
        });
        return;
      }
      if (
        message.action === EventAction.Update ||
        message.action === EventAction.Create
      ) {
        if (
          !existingCalendarEvent ||
          (existingCalendarEvent.eTag !== message.etag &&
            message.etag !== undefined)
        ) {
          await this.getCalendarEvent(calendarEventId, {
            ignoreCache: true
          });
        }
        runInAction(() => {
          // lastUpdatedCalendarEventData shouldn't be updated until the calendar event is added to the cache map.
          //  that way the compononents that use lastUpdatedCalendarEventData don't have to do the API call themselves
          //  they can just instantly use the new model
          this.ui.lastUpdatedCalendarEventData = message;
        });
        await this.onCalendarEventUpdateReminders(message);
      } else {
        runInAction(() => {
          this.ui.lastUpdatedCalendarEventData = message;
        });
        await this.onCEInvoiceStatusUpdateEvent(message);
      }
    } catch (error) {
      if (error instanceof NotFoundError) return; // Don't show error messages if the calendar event has been deleted
      this.root.notification.error(error.message);
    }
  };

  private onCalendarEventUpdateReminders = async (
    event: EntityEventData<{ id: string }>
  ) => {
    const calendarEvent = this.calendarEventsMap.get(event.id);
    if (event.action === EventAction.Update) {
      if (!!calendarEvent && event.etag !== calendarEvent.eTag) {
        const reminder = Array.from(
          this.calendarEventRemindersMap.values()
        ).find(x => x.calendarEventId === calendarEvent.id);

        if (!!reminder) {
          this.getCalendarEventReminder({
            calendarEventId: calendarEvent.id,
            reminderId: reminder.id
          });
        }
      }
    }
  };

  private onCEInvoiceStatusUpdateEvent = async (
    event: EntityEventData<{ id: string }>
  ) => {
    const calendarEvent = this.calendarEventsMap.get(event.id);

    if (event.action === EventAction.InvoiceStatusUpdate) {
      if (!!calendarEvent && calendarEvent.eTag !== event.etag) {
        const ce = await this.gateway.getCalendarEvent(calendarEvent.id);
        this.mergeCalendarEvent(ce);
      }
    }
  };

  private onExtensionUpdateEvent = async (message: EntityEventData) => {
    if (
      message.action === EventAction.Create ||
      message.action === EventAction.Update
    ) {
      const calendarEventId = message.id;
      const existingCalendarEvent = this.calendarEventsMap.get(calendarEventId);
      if (existingCalendarEvent) {
        const calendarEventExtension =
          await this.root.userExperience.getCalendarEventExtension(
            calendarEventId
          );
        runInAction(() => {
          existingCalendarEvent.calendarEventExtension = calendarEventExtension;
        });
      }
    }
  };

  private onWaitingListItemChanged = async (
    event: EntityEventData<{ waitingListId: string }>
  ) => {
    try {
      if (
        event.id != null &&
        (event.action === EventAction.Create ||
          event.action === EventAction.Update)
      ) {
        await this.getWaitingListRecord(event.id);
        runInAction(() => {
          this.recentlyUpdatedWaitingListItemEtag = event.etag;
        });
      }
      if (event.action === EventAction.Delete) {
        runInAction(() => {
          this.waitingListsMap.delete(event.id);
          this.recentlyUpdatedWaitingListItemEtag = event.etag + event.id;
        });
      }
    } catch (error) {
      this.notification.error(error);
    }
  };

  private onTenantSettingsUpdateEvent = async (message: EntityEventData) => {
    try {
      if (message.etag !== this.tenantSettings?.eTag) {
        await this.getTenantSettings();
      }
    } catch (error) {
      this.notification.error(error);
    }
  };

  get core() {
    return this.root.core;
  }
  get practice() {
    return this.root.practice;
  }
  get notification() {
    return this.root.notification;
  }
  get routing() {
    return this.root.routing;
  }

  @observable
  lastAddedCalendarEventId?: string;

  @observable
  recentlyUpdatedWaitingListItemEtag?: string;

  @observable
  recentlyDeletedScheduleListItemId?: string;

  get recurrences() {
    return values(this.recurrenceMap);
  }

  async addCalendarEvent(request: AddCalendarEventDto, baggage?: Baggage) {
    let baggageObject: NewAppointmentBaggageObject = {};

    if (this.ui.newAppointmentStartingPoint) {
      baggageObject.UiOption = this.ui.newAppointmentStartingPoint;
    }

    if (baggage) {
      baggageObject = { ...baggageObject, ...baggage };
    }

    const calendarEvent = await this.gateway
      .addCalendarEvent(request, baggageObject)
      .then(async result => {
        // here we are firing requests for any attendee that is not already in the store
        // preferably, the API will change so that the related resources can be directly expanded in the response
        await this.loadCalendarEventAttendees(result);
        return result;
      })
      .then(this.mergeCalendarEvent);

    this.onAddedCalendarEvent(calendarEvent);
    return calendarEvent;
  }

  @sharePendingPromise()
  async getRecurrence(
    id: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ) {
    if (!options.ignoreCache) {
      const existingRecurrence = this.recurrenceMap.get(id);
      if (existingRecurrence) {
        return existingRecurrence;
      }
    }
    return await this.gateway.getRecurrence(id).then(this.mergeRecurrence);
  }

  getRecurrencesForSeries = (id: string): RecurrenceModel[] => {
    return this.recurrences.filter(item => {
      return item.seriesId === id;
    });
  };

  addRecurrence = async (request: AddRecurrenceDto) => {
    let typeText = "";
    switch (request.type) {
      case CalendarEventType.Appointment:
        typeText = "Appointment series";
        break;
      case CalendarEventType.Unavailable:
        typeText = "Reserve";
        break;
      default:
        typeText = "";
        break;
    }
    try {
      const recurrence = await this.gateway
        .addRecurrence(request)
        .then(this.mergeRecurrence);
      this.notification.success(`${typeText} has been added.`);
      return recurrence;
    } catch (error) {
      this.notification.error(error, {
        messageOverride: `An error occurred adding the ${typeText}`
      });
      throw error;
    }
  };

  updateRecurrence = async (request: Omit<PatchRecurrenceDto, "eTag">) => {
    try {
      const recurrence = await patchModel(
        request,
        req => this.gateway.updateRecurrence(req),
        {
          modelMap: this.recurrenceMap
        }
      );
      this.notification.success("Recurrence updated");
      return recurrence;
    } catch (error) {
      this.notification.error(error, {
        messageOverride: `An error occurred updating the ${this.getRecurrenceFromMap(
          request.id
        )}.`
      });
      throw error;
    }
  };

  async cancelRecurrence(id: string) {
    const recurrence = await this.getRecurrence(id);
    if (!recurrence) {
      return;
    }
    try {
      await this.gateway.deleteRecurrence(id);
      runInAction(() => {
        this.recurrenceMap.delete(id);
      });

      this.notification.success("Series has been deleted.");
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred deleting the series."
      });
      throw error;
    }
  }

  async deleteWaitingListRecord(id: string) {
    const waitingListItem = await this.getWaitingListRecord(id);
    if (!waitingListItem) {
      return;
    }
    try {
      await this.gateway
        .deleteWaitingListItem(id)
        .then(action(() => this.waitingListsMap.delete(id)));

      this.notification.success("Waiting list has been deleted.");
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred deleting the waiting list."
      });
      throw error;
    }
  }

  getRecurrenceFromMap(id: string) {
    const recurrence = this.recurrenceMap.get(id);
    if (!recurrence) {
      throw Error(`Recurrence ${id} not found in store`);
    }
    return `recurrence id: ${recurrence.id}`;
  }

  sendCalendarEventReminder(reminder: SingleCalendarEventReminder) {
    return this.gateway.sendCalendarEventReminder(reminder);
  }

  @action
  undoPendingChanges(calendarEventId: string) {
    const calendarEvent = this.calendarEventsMap.get(calendarEventId);
    if (!calendarEvent) {
      // can happen when appointment has been deleted in the meantime
      return;
    }

    calendarEvent.undoPendingChanges();
  }

  @action
  cancelCalendarEvent(request: Omit<PatchCalendarEventDto, "status" | "eTag">) {
    const calendarEvent = this.getCalendarEventFromMap(request.id);
    if (!calendarEvent) {
      return;
    }

    return this.updateCalendarEvent({
      ...request,
      status: CalendarEventStatus.Cancelled
    });
  }

  @action
  cancelCalendarSeries = async (
    id: string,
    request: CancelCalendarSeriesDto
  ) => {
    try {
      await this.gateway.cancelCalendarSeries(id, request);
      this.notification.success("Updated the calendar series");
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred updating the calendar series"
      });
      throw error;
    }
  };

  @action
  updateCalendarEvent = async (
    request: Omit<PatchCalendarEventDto, "eTag">,
    baggage?: Baggage
  ) => {
    try {
      const calendarEvent = await patchModel(
        request,
        req => this.gateway.updateCalendarEvent(req, baggage),
        {
          modelMap: this.calendarEventsMap
        }
      );
      this.notification.success(
        `${calendarEvent.typeRef?.text ?? "appointment"} ${
          calendarEvent.status === CalendarEventStatus.Cancelled
            ? "cancelled"
            : "updated"
        }.`
      );

      if (
        this.core.tenantDetails!.country === Country.NewZealand &&
        calendarEvent.status === CalendarEventStatus.Cancelled
      ) {
        await this.root.acc.deleteClaimAppointmentDtoByCalendarEventId(
          calendarEvent.id
        );
      }

      return calendarEvent;
    } catch (error) {
      this.notification.error(error, {
        messageOverride: `An error occurred updating the ${
          this.getCalendarEventFromMap(
            request.id
          ).typeRef?.text.toLowerCase() || "appointment"
        }.`
      });
      throw error;
    }
  };

  validateCalendarEvent = async (
    params: ValidateRecurrenceSeriesConflictDto
  ) => {
    return await this.gateway.validateCalendarEvent(params);
  };

  getCalendarEventFromMap(id: string) {
    const appointment = this.calendarEventsMap.get(id);
    if (!appointment) {
      throw Error(`Appointment ${id} not found in store`);
    }
    return appointment;
  }

  async updateCalendarEventAppointmentStatus(
    id: string,
    request: CalendarEventAppointmentStatusChangeDto
  ) {
    try {
      await this.gateway.updateCalendarEventAppointmentStatusChange(
        id,
        request
      );
      this.notification.success("Status successfully updated.");
    } catch (e) {
      this.notification.error(e);
      throw e;
    }
  }

  @action
  async hasFutureCalendarEvents(options: Partial<GetCalendarEventsDto>) {
    const dtoResult = await this.gateway.getCalendarEvents({
      statuses: [CalendarEventStatus.Confirmed],
      startTime: DateTime.now().toISO(),
      ...options
    });
    return !!dtoResult.results.length;
  }

  fetchCalendarEvents(filter: CalendarEventsFilterInterface) {
    const startTime = filter.startTime.startOf(filter.dateRange);
    const endTime = startTime.plus(
      filter.dateRange === "day" ? { days: 1 } : { weeks: 1 }
    );

    return this.getCalendarEvents({
      statuses: filter.statuses,
      attendees: filter.attendees,
      endTime: endTime.toISO(),
      startTime: startTime.toISO()
    });
  }

  async getCalendarEvents(
    request: GetCalendarEventsDto,
    options: {
      loadCalendarEventUsers?: boolean;
      loadCalendarEventContacts?: boolean;
      loadCalendarEventExtensions?: boolean;
      baggage?: UIOptionBaggage;
    } = {
      loadCalendarEventUsers: true,
      loadCalendarEventContacts: true,
      loadCalendarEventExtensions: true,
      baggage: { UiOption: AppointmentSearch.SearchBox }
    }
  ) {
    const { results: dtoResults, ...rest } =
      await this.gateway.getCalendarEvents(request, options.baggage);

    const results = dtoResults.map(this.mergeCalendarEvent);

    const loadCalendarEventPromises = [
      options.loadCalendarEventUsers
        ? this.loadCalendarEventUsers(dtoResults)
        : undefined,
      options.loadCalendarEventContacts
        ? this.loadCalendarEventContacts(dtoResults)
        : undefined,
      options.loadCalendarEventExtensions
        ? this.loadCalendarEventExtensions(results)
        : undefined
    ].filter(isDefined);

    await Promise.all(loadCalendarEventPromises);

    return { results, ...rest };
  }

  @sharePendingPromise()
  async getCalendarEvent(
    id: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ) {
    if (!options.ignoreCache) {
      const existingCalendarEvent = this.calendarEventsMap.get(id);
      if (existingCalendarEvent) {
        if (existingCalendarEvent.calendarEventRecurrenceId !== undefined) {
          const existingCalendarEventRecurrence = this.recurrenceMap.get(
            existingCalendarEvent.calendarEventRecurrenceId
          );

          // Always need the latest series, items deleted and added dynamically
          if (
            existingCalendarEventRecurrence &&
            existingCalendarEventRecurrence.seriesId
          ) {
            await this.getCalendarSeries(
              existingCalendarEventRecurrence.seriesId,
              true
            );
          }
        }

        return existingCalendarEvent;
      }
    }

    const calendarEvent = await this.gateway.getCalendarEvent(id);

    if (calendarEvent.calendarEventRecurrenceId !== undefined) {
      const recurrence = await this.getRecurrence(
        calendarEvent.calendarEventRecurrenceId
      );
      if (recurrence && recurrence.seriesId) {
        await Promise.all([
          this.getCalendarSeries(recurrence.seriesId),
          this.getRecurrenceEvents(recurrence.seriesId)
        ]);
      }
    }
    await this.loadCalendarEventAttendees(calendarEvent);
    return this.mergeCalendarEvent(calendarEvent);
  }

  private loadCalendarEventAttendees = async (
    calendarEvent: CalendarEventDto
  ) => {
    await Promise.all<User | Contact>(
      calendarEvent.attendees.filter(isKnownAttendeeType).map(attendee => {
        switch (attendee.type) {
          case "USER": {
            return this.core.getUser(attendee.attendeeId);
          }
          default: {
            return this.practice.getContact(attendee.attendeeId);
          }
        }
      })
    );
    if (calendarEvent.bookedBy) {
      await this.core.getUser(calendarEvent.bookedBy);
    }
  };

  @action
  getAppointmentCancellationReasons = async (
    params?: GetAppointmentCancellationReasonDto
  ) => {
    const appointmentCancellationReasons =
      await this.gateway.getAppointmentCancellationReasons(params);

    return appointmentCancellationReasons.map(
      this.mergeAppointmentCancellationReason
    );
  };

  @sharePendingPromise()
  getCalendarCancellationReason(id: string) {
    const reason = this.appointmentCancellationReasonsMap.get(id);
    if (reason) {
      return Promise.resolve(reason);
    }

    return this.gateway
      .getAppointmentCancellationReason(id)
      .then(this.mergeAppointmentCancellationReason);
  }

  addCalendarCancellationReasons = async (
    request: AddAppointmentCancellationReasonDto
  ) => {
    const addAppointmentCancellationReason =
      await this.gateway.addAppointmentCancellationReasons(request);

    return this.mergeAppointmentCancellationReason(
      addAppointmentCancellationReason
    );
  };

  @action
  updateCalendarCancellationReasons = async (
    request: UpdateAppointmentCancellationReasonDto
  ) => {
    const updateAppointmentCancellationReason =
      await this.gateway.updateAppointmentCancellationReasons(request);

    return this.mergeAppointmentCancellationReason(
      updateAppointmentCancellationReason
    );
  };

  addProviderComment = async (
    calendarEventId: string,
    comment: string | undefined
  ) => {
    const calendarEvent = await this.getCalendarEvent(calendarEventId);
    const result = await this.gateway.addProviderComment({
      calendarEventId,
      providerComment: comment,
      etag: calendarEvent.eTag
    });
    return this.mergeCalendarEvent(result);
  };

  @action
  deleteAppointmentCancellationReason = async (id: string) => {
    await this.gateway.deleteAppointmentCancellationReason(id);

    return runInAction(() => {
      this.appointmentCancellationReasonsMap.delete(id);
    });
  };

  @action
  private mergeAppointmentCancellationReason = (
    dto: AppointmentCancellationReasonDto
  ) =>
    mergeModel({
      dto,
      getNewModel: () => new AppointmentCancellationReason(dto),
      map: this.appointmentCancellationReasonsMap
    });

  @action
  private mergeWaitingList = (dto: WaitingListItemDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new WaitingListItemModel(dto, this.root),
      map: this.waitingListsMap
    });
  };

  @action
  public mergeCalendarEvent = (dto: CalendarEventDto) =>
    mergeModel({
      dto,
      getNewModel: () => new CalendarEvent(this.root, dto),
      map: this.calendarEventsMap
    });

  @action
  private onAddedCalendarEvent(calendarEvent: CalendarEvent): void {
    this.lastAddedCalendarEventId = calendarEvent.id;
    this.notification.success(this.calendarEventSuccessMessage(calendarEvent));
  }

  @action
  private mergeRecurrence = (dto: RecurrenceDto) =>
    mergeModel({
      dto,
      getNewModel: () => new RecurrenceModel(this.root, dto),
      map: this.recurrenceMap
    });

  @sharePendingPromise()
  async getOrgUnitAvailability(
    orgUnitId: string,
    options?: { ignoreCache?: boolean }
  ) {
    let availability = this.orgUnitAvailabilityMap.get(orgUnitId);

    if (!availability || options?.ignoreCache) {
      availability = await this.gateway
        .getOrgUnitAvailability(orgUnitId)
        .then(this.mergeOrgUnitAvailability);
    }

    return availability!;
  }

  @action
  private mergeOrgUnitAvailability = (dto: OrgUnitAvailabilityDto) => {
    let availability = this.orgUnitAvailabilityMap.get(dto.orgUnitId);

    if (!availability) {
      availability = new OrgUnitAvailability(dto);
      this.orgUnitAvailabilityMap.set(dto.orgUnitId, availability);
    } else {
      availability.updateFromDto(dto);
    }

    return availability;
  };

  @action
  updateOrgUnitAvailability(request: PatchOrgUnitAvailabilityDto) {
    return this.gateway
      .updateOrgUnitAvailability(request)
      .then(response => ({ ...response, orgUnitId: request.orgUnitId }))
      .then(this.mergeOrgUnitAvailability);
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async getUserAvailabilitySlot(
    request: AvailabilitySlotQuery
  ): Promise<AvailabilitySlots> {
    const promise = this.gateway
      .getAvailabilitySlot({
        startDate: request.startDate.toISODate(),
        endDate: request.endDate.toISODate(),
        userId: request.userId,
        orgUnitIds: request.orgUnitIds,
        duration: request.duration,
        excludeCalendarEvent: request.excludeCalendarEvent!!
      })
      .then(data => new AvailabilitySlots(data, request.userId));

    try {
      await promise;
    } catch (e) {
      this.notification.error(e);
    }

    return promise;
  }

  creatingTemporaryReservation = maybePromiseObservable();

  async addTemporaryReservation(request: AddCalendarEventDto) {
    try {
      const promise = this.gateway
        .addTemporaryReservation(request)
        .then(this.mergeCalendarEvent)
        .then(async result => {
          // here we are firing requests for any attendee that is not already in the store
          // preferably, the API will change so that the related resources can be directly expanded in the response
          await this.loadCalendarEventAttendees(result.dto);
          return result;
        });

      this.creatingTemporaryReservation.set(promise);
      return promise;
    } catch (e) {
      this.notification.error(e, {
        messageOverride: "An error occurred creating the reservation."
      });
    }
    return;
  }

  @sharePendingPromise()
  async deleteTemporaryReservation() {
    await when(() => !this.creatingTemporaryReservation.pending);

    try {
      await this.gateway.deleteTemporaryReservation();
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred deleting the temporary reservation."
      });
      throw error;
    }
  }

  /**
   * Gets users working ours from default location
   * (this function must be removed once multi location feature is finished 'hasMultiLocationOrgUnit', replaced by getUserWorkingHoursAllLocations)
   *
   */
  getUsersWorkingHours(
    request: UserWorkingHoursQueryInterface
  ): Promise<UsersTimeRanges> {
    return this.gateway
      .getUserTimeRange({
        dateTimeFrom: request.from.toISODate(),
        dateTimeTo: request.to.toISODate(),
        orgUnitId: request.orgUnitId,
        isStandardHours: request.isStandardHours,
        userIds: request.userIds
      })
      .then(data => {
        return data.userTimeRangeGroup.reduce<UsersTimeRanges>(
          (result, value) => {
            result[value.userId] = new TimeRanges(
              value.timeRange.map(x => ({
                from: DateTime.fromISO(x.startTime),
                to: DateTime.fromISO(x.endTime)
              }))
            );
            return result;
          },
          {}
        );
      });
  }

  /**
   * Gets users working hours from ALL locations
   */
  async getUserWorkingHoursAllLocations(
    request: Omit<UserWorkingHoursQueryInterface, "orgUnitId">
  ): Promise<UsersOrgUnitTimeRanges[]> {
    const data = await this.gateway.getUserTimeRange({
      dateTimeFrom: request.from.toISODate(),
      dateTimeTo: request.to.toISODate(),
      orgUnitId: undefined,
      isStandardHours: request.isStandardHours,
      userIds: request.userIds
    });

    const result = data.userTimeRangeGroup.reduce<UsersOrgUnitTimeRanges[]>(
      (arr, value) => {
        let item: UsersOrgUnitTimeRanges | undefined = arr.find(
          a => a.userId === value.userId
        );
        if (!item) {
          item = {
            userId: value.userId,
            orgUnitRanges: new TimeRangesOrgUnit(
              value.timeRange.map(x => ({
                orgUnitId: value.orgUnitId,
                from: DateTime.fromISO(x.startTime),
                to: DateTime.fromISO(x.endTime)
              }))
            )
          };
          arr.push(item);
        } else {
          item.orgUnitRanges.timeRanges.push(
            ...value.timeRange.map(x => ({
              orgUnitId: value.orgUnitId,
              from: DateTime.fromISO(x.startTime),
              to: DateTime.fromISO(x.endTime)
            }))
          );
        }
        return arr;
      },
      []
    );
    return result;
  }

  async getOrgUnitWorkingHours(
    request: OrgUnitWorkingHoursQueryInterface
  ): Promise<TimeRanges> {
    return await this.gateway
      .getOrgUnitAvailableTimeRange({
        dateTimeFrom: request.from.toISODate(),
        dateTimeTo: request.to.toISODate(),
        orgUnitId: request.orgUnitId
      })
      .then(
        data =>
          new TimeRanges(
            data.map(x => ({
              from: DateTime.fromISO(x.startTime),
              to: DateTime.fromISO(x.endTime)
            }))
          )
      );
  }

  @sharePendingPromise()
  async getUserAvailability(
    userId: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ) {
    if (!this.ui.hidePreviousExceptions) {
      this.ui.onTogglePreviousExceptions(true);
    }

    const userAvailability = this.userAvailabilityMap.get(userId);
    if (!options?.ignoreCache && userAvailability) {
      return userAvailability;
    }

    return await this.gateway
      .getUserAvailability(userId, this.core.locationId)
      .then(this.mergeUserAvailability);
  }

  async getUserAvailabilities(
    userId: string[]
  ): Promise<UserAvailabilityModel[]> {
    const availabilities = await this.gateway.getUserAvailabilities({
      userId
    });
    return availabilities.map(a => this.mergeUserAvailability(a));
  }

  updateUserAvailability(data: PatchUserAvailabilityDto) {
    return this.gateway
      .updateUserAvailability(data)
      .then(this.mergeUserAvailability);
  }

  @action
  public mergeUserAvailability = (dto: UserAvailabilityDto) => {
    let userAvailability = this.userAvailabilityMap.get(dto.userId);
    if (!userAvailability) {
      userAvailability = new UserAvailabilityModel(dto);
      this.userAvailabilityMap.set(userAvailability.userId, userAvailability);
    } else {
      userAvailability.updateFromDto(dto);
    }

    return userAvailability;
  };

  @sharePendingPromise()
  async getUserUnavailability(
    userId: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ) {
    if (!this.ui.hidePreviousReserves) {
      this.ui.onTogglePreviousReserves(true);
    }

    const existingUserUnavailability = this.userUnavailabilityMap.get(userId);
    if (!options?.ignoreCache && existingUserUnavailability) {
      return existingUserUnavailability;
    }

    const userUnavailabilityModel = await this.gateway
      .getUserUnavailability(userId, this.core.locationId)
      .then(this.mergeUserUnavailability);

    userUnavailabilityModel.calendarEventRecurrences.map(this.mergeRecurrence);
    return userUnavailabilityModel;
  }

  @action
  async updateUserUnavailability(data: PatchUserUnavailabilityDto) {
    const userUnavailabilityModel = await this.gateway
      .updateUserUnavailability(data)
      .then(this.mergeUserUnavailability);

    userUnavailabilityModel.calendarEventRecurrences.map(this.mergeRecurrence);
    return userUnavailabilityModel;
  }

  @action
  private mergeUserUnavailability = (dto: UserUnavailabilityDto) => {
    let userUnavailability = this.userUnavailabilityMap.get(dto.userId);
    if (!userUnavailability) {
      userUnavailability = new UserUnavailabilityModel(dto);
      this.userUnavailabilityMap.set(
        userUnavailability.userId,
        userUnavailability
      );
    } else {
      userUnavailability.updateFromDto(dto);
    }

    return userUnavailability;
  };

  private loadCalendarEventContacts = async (
    calendarEventDto: CalendarEventDto[]
  ) => {
    // here we extract all contact ids from the appointments result that are
    // not already loaded in the store
    const contactIds = calendarEventDto.reduce<string[]>(
      (list, calendarEvent) => {
        return list.concat(
          calendarEvent.attendees
            .filter(
              y =>
                (y.type === AttendeeTypeEnum.patient ||
                  y.type === AttendeeTypeEnum.contact) &&
                !!y.attendeeId
            )
            .map(x => x.attendeeId!)
            .filter(id => !this.practice.contactsMap.has(id))
        );
      },
      []
    );

    await this.practice.getContactsById(contactIds);
  };

  loadCalendarEventExtensions = async (calendarEvents: CalendarEvent[]) => {
    const calendarEventExtensions =
      await this.root.userExperience.getCalendarEventExtensions(
        calendarEvents.map(({ id }) => id)
      );

    runInAction(() => {
      calendarEventExtensions.forEach(calendarEventExtension => {
        const calendarEvent = this.calendarEventsMap.get(
          calendarEventExtension.calendarEventId
        );
        calendarEvent!.calendarEventExtension = calendarEventExtension;
      });
    });
  };

  getWaitingListRecord = async (id: string) => {
    const promise = this.gateway
      .getWaitingListItem(id)
      .then(this.mergeWaitingList);
    try {
      await promise;
    } catch (e) {
      this.notification.error(e);
    }

    return promise;
  };

  getLocationWaitingListItems = async (locationId: string) => {
    try {
      const dtoResult = await this.gateway.getWaitingListItems({
        orgUnitIds: [locationId]
      });

      return dtoResult.map(this.mergeWaitingList);
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred loading waiting list."
      });
      throw error;
    }
  };

  getWaitingListRecordsFilteredValues = async (filter: WaitingListFilter) => {
    try {
      let dtoResult = await this.gateway.getWaitingListItems({
        expiryDate: DateTime.today().toISO(),
        orgUnitIds: filter.orgUnitIds,
        attendees: filter.attendees,
        durations: filter.duration,
        calenderEventId: filter.calenderEventId,
        priorities: filter.priority,
        appointmentTypeIds: filter.appointmentTypeId,
        name: filter.patientName,
        anyProvider: filter.anyProvider ? !!filter.anyProvider : undefined
      });

      if (filter.anyProvider !== undefined) {
        const filterValue = !!Number(filter.anyProvider) ? true : undefined;
        dtoResult = dtoResult.filter(item => item.anyProvider === filterValue);
      }

      const attendees = flatten(dtoResult.map(d => d.attendees));

      const contactIds = attendees
        .filter(a => a.type !== AttendeeTypeEnum.user)
        .map(a => a.attendeeId);

      const usersIds = attendees
        .filter(a => a.type === AttendeeTypeEnum.user)
        .map(a => a.attendeeId);

      await Promise.all([
        this.practice.getContactsById(contactIds),
        this.core.getUsersByIds(usersIds)
      ]);

      return dtoResult.map(this.mergeWaitingList);
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred loading waiting list."
      });
      throw error;
    }
  };

  @sharePendingPromise()
  async getCalendarSeries(
    id: string,
    ignoreCache: boolean = false
  ): Promise<void> {
    if (!ignoreCache) {
      if (this.recurrenceMap.size > 0) {
        return;
      }
    }

    const calendarSeries = await this.gateway.getCalendarSeries(id);
    calendarSeries.recurrences.forEach(item => {
      this.mergeRecurrence(item);
    });
    return;
  }

  @sharePendingPromise()
  async getRecurrenceEvents(
    id: string,
    ignoreCache: boolean = false
  ): Promise<void> {
    if (!ignoreCache) {
      const existingEvents =
        Array.from(this.calendarEventsMap.values()).filter(
          x => x.calendarEventRecurrenceId === id
        ).length > 0;
      if (existingEvents) {
        return;
      }
    }

    const recurrenceEvents = await this.gateway.getCalendarEvents({
      statuses: [],
      calendarEventRecurrenceId: id
    });

    recurrenceEvents.results.forEach(item => {
      this.mergeCalendarEvent(item);
    });
    return;
  }

  async amendRecurrenceSeries(
    request: AddRecurrenceDto,
    calendarEventId: string
  ) {
    try {
      const recurrence = await this.gateway.amendCalendarSeries(
        calendarEventId,
        request
      );
      this.notification.success("Appointment series has been updated.");
      return this.mergeRecurrence(recurrence);
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred updating the Appointment series"
      });
      throw error;
    }
  }

  private loadCalendarEventUsers = async (
    calendarEventDto: CalendarEventDto[]
  ) => {
    // here we extract all user ids from the appointments result that are not already
    // loaded in the store
    const userIds = calendarEventDto.reduce<string[]>((list, calendarEvent) => {
      return list.concat(
        calendarEvent.attendees
          .filter(y => y.type === "USER" && !!y.attendeeId)
          .map(x => x.attendeeId!)
          .concat(calendarEvent.bookedBy ? [calendarEvent.bookedBy] : [])
          .filter(id => !this.core.userMap.has(id))
      );
    }, []);

    await Promise.all(userIds.map(id => this.core.getUser(id)));
  };

  addWaitingListItem = async (data: AddWaitingListItemDto) => {
    const waitingListItem = await this.gateway
      .addWaitingListItem(data)
      .then(this.mergeWaitingList);
    this.notification.success("A waiting list entry has been added.");

    return waitingListItem;
  };

  updateWaitingListItem = async (
    request: Omit<UpdateWaitingListItemDto, "eTag">
  ) => {
    const waitingListItem = getOrThrow(this.waitingListsMap, request.id);
    return await this.gateway.updateWaitingListItem({
      ...request,
      eTag: waitingListItem.eTag
    });
  };

  async getRecurringAppointmentHelperText(
    userId: string,
    request: GetRecurringAppointmentConflictDto
  ) {
    try {
      return await this.gateway.getRecurringAppointmentHelperText(userId, {
        ...request
      });
    } catch (error) {
      this.notification.error(error);
      throw error;
    }
  }

  @sharePendingPromise()
  async getCalendarEventAppointmentStatusChangeDateTimeLimit(id: string) {
    try {
      return await this.gateway.getCalendarEventAppointmentStatusChangeDateTimeLimit(
        id
      );
    } catch (error) {
      this.notification.error(error);
    }
    return undefined;
  }

  loadCalendarEventRemindersBySearchArgs = async (
    request: CalendarEventReminderSearchArgs,
    options?: { loadCalendarEventReminderContacts: boolean }
  ): Promise<CalendarEventReminderSearchModel[]> => {
    const results =
      await this.gateway.getCalendarEventRemindersBySearchArgs(request);

    if (options?.loadCalendarEventReminderContacts) {
      const contactIds = unique(results.map(r => r.patientId));
      await this.practice.getContactsById(contactIds);
    }

    const items: CalendarEventReminderSearchModel[] = [];

    results.forEach(dto => {
      this.mergeCalendarEvent(dto.calendarEvent);
      if (dto.reminder) this.mergeCalendarEventReminder(dto.reminder);
      if (dto.message) this.mergeCalendarEventReminderMessage(dto.message);
      items.push(new CalendarEventReminderSearchModel(this.root, dto));
    });

    // default sorting of date in ascending order
    items.sort(
      (
        a: CalendarEventReminderSearchModel,
        b: CalendarEventReminderSearchModel
      ) => {
        return (
          a.calendarEvent?.startDateTime.valueOf() -
          b.calendarEvent?.startDateTime.valueOf()
        );
      }
    );

    return items;
  };

  @action
  private mergeCalendarEventReminderMessage = (
    dto: CalendarEventReminderMessageDto
  ) =>
    mergeModel({
      dto,
      getNewModel: () => new CalendarEventReminderMessage(dto),
      map: this.calendarEventRemindersMessagesMap
    });

  async addCalendarEventReminder(
    calendarEventId: string,
    request: AddCalendarEventReminderDto
  ) {
    return await this.gateway
      .addCalendarEventReminder(calendarEventId, request)
      .then(this.mergeCalendarEventReminder);
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async getCalendarEventReminder(
    args: GetCalendarEventReminderArgs,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ) {
    if (!options.ignoreCache) {
      const existingCalendarEventReminder = this.calendarEventRemindersMap.get(
        args.reminderId
      );
      if (existingCalendarEventReminder) {
        return existingCalendarEventReminder;
      }
    }
    return await this.gateway
      .getCalendarEventReminder(args.calendarEventId, args.reminderId)
      .then(this.mergeCalendarEventReminder);
  }

  @action
  updateCalendarEventReminder = async (
    calendarEventId: string,
    reminderId: string,
    request: Omit<UpdateCalendarEventReminderDto, "eTag">
  ) => {
    try {
      const recurrence = await patchModel(
        request,
        req =>
          this.gateway.updateCalendarEventReminder(
            calendarEventId,
            reminderId,
            req
          ),
        {
          modelMap: this.calendarEventRemindersMap
        }
      );
      this.notification.success("Status has been updated");
      return recurrence;
    } catch (error) {
      this.notification.error(error, {
        messageOverride: `An error occurred updating the CalendarEventReminder ${request.id}.`
      });
      throw error;
    }
  };

  @action
  private mergeCalendarEventReminder = (dto: CalendarEventReminderDto) =>
    mergeModel({
      dto,
      getNewModel: () => new CalendarEventReminder(this.root, dto),
      map: this.calendarEventRemindersMap
    });

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async getCalendarEventReminderMessages(
    args: GetCalendarEventReminderArgs
  ): Promise<CalendarEventReminderMessageDto[]> {
    try {
      return await this.gateway.getCalendarEventReminderMessages(
        args.calendarEventId,
        args.reminderId
      );
    } catch (e) {
      this.notification.error(e);
    }

    return [];
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async getCalendarEventReminderReplies(
    args: GetCalendarEventReminderArgs
  ): Promise<CalendarEventReminderReplyDto[]> {
    try {
      return await this.gateway.getCalendarEventReminderReplies(
        args.calendarEventId,
        args.reminderId
      );
    } catch (e) {
      this.notification.error(e);
    }

    return [];
  }

  getAppointmentReminderJobs = async (): Promise<AppointmentReminderJob[]> => {
    const dtoResult = await this.gateway.getAppointmentReminderJobs();
    return dtoResult.map(this.mergeAppointmentReminderJob);
  };

  @sharePendingPromise()
  async getAppointmentReminderJob(
    id: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ) {
    if (!options.ignoreCache) {
      const existingAppointmentReminderJob =
        this.appointmentReminderJobsMap.get(id);
      if (existingAppointmentReminderJob) {
        return existingAppointmentReminderJob;
      }
    }
    return await this.gateway
      .getAppointmentReminderJob(id)
      .then(this.mergeAppointmentReminderJob);
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  async getAppointmentReminderJobPreview(
    request: AppointmentReminderJobPreviewArgs
  ): Promise<AppointmentReminderJobPreviewDto> {
    try {
      return await this.gateway.getAppointmentReminderJobPreview(request);
    } catch (error) {
      this.notification.error(error, {
        messageOverride: "An error occurred loading job preview."
      });
      throw error;
    }
  }

  @action
  addAppointmentReminderJob = async (request: AddAppointmentReminderJobDto) => {
    const appointmentReminderReminderJob =
      await this.gateway.addAppointmentReminderJob(request);
    return this.mergeAppointmentReminderJob(appointmentReminderReminderJob);
  };

  updateAppointmentReminderJob = async (
    request: PatchAppointmentReminderJobDto
  ) => {
    try {
      const apptReminderJobDto =
        await this.gateway.updateAppointmentReminderJob(request);

      return this.mergeAppointmentReminderJob(apptReminderJobDto);
    } catch (error) {
      this.notification.error(error, {
        messageOverride:
          "An error occurred updating the Appointment Reminder Job."
      });
      throw error;
    }
  };

  async deleteAppointmentReminderJob(id: string) {
    return await this.gateway
      .deleteAppointmentReminderJob(id)
      .then(action(() => this.appointmentReminderJobsMap.delete(id)));
  }

  async runAppointmentReminderJob(
    jobId: string,
    jobRunId: string | undefined = undefined
  ) {
    const request: AppointmentReminderJobRunArgsDto = {
      rerunAppointmentReminderJobRunId: jobRunId
    };
    return await this.gateway.runAppointmentReminderJob(jobId, request);
  }

  @action
  private mergeAppointmentReminderJob = (dto: AppointmentReminderJobDto) =>
    mergeModel({
      dto,
      getNewModel: () => new AppointmentReminderJob(dto),
      map: this.appointmentReminderJobsMap
    });

  getAllAppointmentReminderJobRunSummaries = async (): Promise<
    AppointmentReminderJobRunSummaryDto[]
  > => {
    const dtoResult =
      await this.gateway.getAllAppointmentReminderJobRunSummaries();
    return dtoResult;
  };

  getAppointmentReminderJobRunSummaries = async (
    jobId: string
  ): Promise<AppointmentReminderJobRunSummaryDto[]> => {
    const dtoResult =
      await this.gateway.getAppointmentReminderJobRunSummaries(jobId);
    return dtoResult;
  };

  async addCalendarEventFormInstance(request: CalendarEventFormInstanceDTO) {
    try {
      return await this.gateway.addCalendarEventFormInstance(request);
    } catch (error) {
      this.notification.error(error, {
        messageOverride:
          "An error occurred linking the appointment with the deployed form."
      });
      throw error;
    }
  }

  getAppointmentConfirmationCampaigns = async (): Promise<
    AppointmentConfirmationCampaign[]
  > => {
    const dtoResult = await this.gateway.getAppointmentConfirmationCampaigns();
    return dtoResult.map(this.mergeAppointmentConfirmationCampaign);
  };

  @sharePendingPromise()
  async getAppointmentConfirmationCampaign(
    id: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ) {
    if (!options.ignoreCache) {
      const existingAppointmentConfirmationCampaign =
        this.appointmentConfirmationCampaignsMap.get(id);
      if (existingAppointmentConfirmationCampaign) {
        return existingAppointmentConfirmationCampaign;
      }
    }
    return await this.gateway
      .getAppointmentConfirmationCampaign(id)
      .then(this.mergeAppointmentConfirmationCampaign);
  }

  @action
  private mergeAppointmentConfirmationCampaign = (
    dto: AppointmentConfirmationCampaignDto
  ) =>
    mergeModel({
      dto,
      getNewModel: () => new AppointmentConfirmationCampaign(dto),
      map: this.appointmentConfirmationCampaignsMap
    });

  addAppointmentConfirmationCampaign = async (
    request: AddAppointmentConfirmationCampaignDto
  ) => {
    const appointmentConfirmationCampaign =
      await this.gateway.addAppointmentConfirmationCampaign(request);
    return this.mergeAppointmentConfirmationCampaign(
      appointmentConfirmationCampaign
    );
  };

  updateAppointmentConfirmationCampaign = async (
    request: PatchAppointmentConfirmationCampaignDto
  ) => {
    const appointmentConfirmationCampaignDto =
      await this.gateway.updateAppointmentConfirmationCampaign(request);

    return this.mergeAppointmentConfirmationCampaign(
      appointmentConfirmationCampaignDto
    );
  };

  updateCalendarEventAttendeeStatusChange = (
    calendarEventId: string,
    attendeeId: string,
    appointmentStatus: AppointmentStatusCode
  ) => {
    return this.gateway.updateCalendarEventAttendeeStatusChange(attendeeId, {
      appointmentStatus,
      calendarEventId
    });
  };

  async getCalendarEventConfirmation(id: string) {
    return this.gateway
      .getCalendarEventConfirmation(id)
      .catch(catchNotFoundError);
  }

  private calendarEventSuccessMessage({
    startDateTime,
    type,
    contact,
    user
  }: CalendarEvent): string {
    const eventTime = `at ${startDateTime.toFormat(
      TIME_FORMATS.DEFAULT_TIME_FORMAT
    )} on ${startDateTime.toFormat(DATE_FORMATS.LONG_DATE_WITHOUT_TIME)}`;

    switch (type) {
      case CalendarEventType.Appointment: {
        return contact
          ? `An appointment ${eventTime} for ${contact.name} has been added to the calendar`
          : `An appointment ${eventTime} has been added to the calendar`;
      }
      case CalendarEventType.Meeting: {
        return contact
          ? `A meeting ${eventTime} for ${contact.name} has been added to the calendar`
          : `A meeting ${eventTime} has been added to the calendar`;
      }
      //unavailable
      default: {
        return user
          ? `Unavailable ${eventTime} for ${user.fullName} has been added to the calendar`
          : `Unavailable ${eventTime} has been added to the calendar`;
      }
    }
  }

  @action
  private mergeAppointmentType = (dto: AppointmentTypeDto) => {
    return mergeModel({
      dto,
      getNewModel: () => new AppointmentType(dto),
      map: this.appointmentTypesMap
    });
  };

  @action
  private mergeAppointmentEncounters = (dto: AppointmentEncountersDto) => {
    return mergeModel({
      dto,
      map: this.appointmentEncountersMap,
      getNewModel: () => new AppointmentEncounters(dto)
    });
  };

  @computed
  get activeAppointmentTypes() {
    return values(this.appointmentTypesMap)
      .filter(x => !x.isInactive)
      .sort((a: AppointmentType, b: AppointmentType) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      });
  }

  get allAppointmentTypes() {
    return Array.from(values(this.appointmentTypesMap)).sort(
      (a: AppointmentType, b: AppointmentType) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      }
    );
  }

  @sharePendingPromise()
  loadAppointmentTypes = async (): Promise<AppointmentType[] | undefined> => {
    //fix for when load patient record adds single appointment type to the map
    if (this.appointmentTypesMap && this.appointmentTypesMap.size > 1) {
      return new Promise<AppointmentType[]>(resolve => {
        resolve(Array.from(this.appointmentTypesMap.values()));
      });
    }
    return this.fetchAppointmentTypes({
      includeInactive: true
    });
  };

  async addAppointmentType(data: AddAppointmentTypeDto) {
    const promise = this.gateway
      .addAppointmentType(data)
      .then(this.mergeAppointmentType);

    const appointmentType = await promise;
    this.notification.success(`${appointmentType.name} has been added.`);

    return appointmentType;
  }

  @action
  async updateAppointmentType(request: PatchAppointmentTypeDto) {
    const resource = await patchModel(
      request,
      req => this.gateway.updateAppointmentType(req),
      { modelMap: this.appointmentTypesMap }
    );

    this.notification.success(`${resource.name} has been updated.`);
    return resource;
  }

  @sharePendingPromise({ keyResolver: deepEqualResolver })
  fetchAppointmentTypes = (filter: AppointmentTypesFilter = {}) => {
    const { includeInactive, isInternalStatic } = filter;
    const isInactive = includeInactive ? undefined : false;
    const STANDARD_INTERVAL = 15;

    return this.gateway
      .getAppointmentTypes({
        isInactive,
        search: filter.search && wildCardCheck(filter.search),
        startDurations: filter.durations,
        standardInterval: STANDARD_INTERVAL,
        isInternalStatic: isInternalStatic ?? false
      })
      .then(results => {
        return observable<AppointmentType[]>(
          results.map(this.mergeAppointmentType)
        );
      });
  };

  @sharePendingPromise()
  getAppointmentType(
    id: string,
    options: { ignoreCache: boolean } = { ignoreCache: false }
  ) {
    if (!options.ignoreCache) {
      const model = this.appointmentTypesMap.get(id);
      if (model) {
        return Promise.resolve(model);
      }
    }

    return this.gateway.getAppointmentType(id).then(this.mergeAppointmentType);
  }

  private onAppointmentTypeEvent = async (event: EntityEventData) => {
    runInAction(() => {
      this.ui.lastUpdatedAppointmentTypeETag = event.etag;
    });

    try {
      const type = this.appointmentTypesMap.get(event.id);

      if (
        event.action === EventAction.Create ||
        event.action === EventAction.Update
      ) {
        if (!type || (type && type.eTag !== event.etag)) {
          await this.getAppointmentType(event.id, { ignoreCache: true });
        }
      }
    } catch (error) {
      this.notification.error(error);
    }
  };

  getAppointmentEncounters = async (
    request: GetAppointmentEncountersRequest
  ) => {
    const dtos = await this.gateway.getAppointmentEncounters(request);
    return dtos.map(this.mergeAppointmentEncounters);
  };

  addAppointmentEncounters = async (data: AddAppointmentEncountersDto) => {
    const dto = await this.gateway.addAppointmentEncounters(data);
    return this.mergeAppointmentEncounters(dto);
  };

  async getTenantSettings(): Promise<BookingTenantSettings | undefined> {
    try {
      const response = await this.gateway.getTenantSettings();
      runInAction(() => {
        this.tenantSettings = new BookingTenantSettings({
          ...response,
          id: this.root.core.tenantDetails!.id
        });
      });
      return this.tenantSettings;
    } catch (error) {
      return catchNotFoundError(error);
    }
  }

  @action
  async addTenantSettings(request: AddBookingTenantSettingsDto) {
    try {
      const response = await this.gateway.addTenantSettings(request);

      runInAction(() => {
        this.tenantSettings = new BookingTenantSettings({
          ...response,
          id: this.root.core.tenantDetails!.id
        });
      });
    } catch (error) {
      throw error;
    }
  }

  @action
  async updateTenantSettings(request: PatchBookingTenantSettingsDto) {
    try {
      const response = await this.gateway.updateTenantSettings(request);

      runInAction(() => {
        this.tenantSettings = new BookingTenantSettings({
          ...response,
          id: this.root.core.tenantDetails!.id
        });
      });
    } catch (error) {
      throw error;
    }
  }
}
