/* eslint-disable no-param-reassign */
import { action, computed, observable } from "mobx";

import {
  Router,
  RouterNavigateOptions,
  RouterState,
  To
} from "@remix-run/router";

import { Location } from "./RouterStore.ts";

// A temporary store based on mobx router but using v6 router instead of history.
export class ReactRouterStore {
  @observable public _location: Location;

  private appRouter: Router;

  public init(appRouter: Router) {
    this.updateLocation(appRouter.state);
    this.appRouter = appRouter;
    this.appRouter.subscribe(this.updateLocation);
  }

  @computed
  public get location(): Location {
    return this._location;
  }

  private updateLocation = action((newState: RouterState) => {
    this._location = newState.location;
  });

  public push = (to: To, opts?: RouterNavigateOptions) => {
    this.appRouter.navigate(to, opts);
  };

  public replace = (to: To, opts?: RouterNavigateOptions) => {
    this.appRouter.navigate(to, { ...opts, replace: true });
  };

  public back = () => {
    this.appRouter.navigate(-1);
  };
}
